/* eslint-disable react/prop-types */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/function-component-definition */

// react imports
import { useState, useEffect } from "react";

// react router imports
import { useNavigate } from "react-router-dom";

// @mui material components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";

// images
// import BrochureMockUp from "assets/images/product-images/brochureMockUp.png";
// import StationaryMockUp from "assets/images/product-images/stationaryMockUp.png";
// import PosterMockUp from "assets/images/product-images/posterMockUp.png";

// firebase imports
import { getDocs, collection, query, orderBy } from "firebase/firestore";

// Material Dashboard 2 React context
import { useMaterialUIController, setPatient, setUpdate } from "context";

import { db } from "../../../../../Firebase";

export default function data() {
  const [controller, dispatch] = useMaterialUIController();
  const { currentStudy } = controller;
  const navigate = useNavigate();

  const [rowArray, setRowArray] = useState([]);
  const [CSVArray, setCSVArray] = useState([]);

  // const newDate = new Date();

  // const contactedOptions = {
  //   uncontacted: "uncontacted",
  //   contactedPhone: "contacted by phone",
  //   contactedEmail: "contacted by email",
  //   appointment: "appointment made",
  //   rejected: "rejected",
  // };

  // const contactedOptionColors = {
  //   uncontacted: "warning",
  //   contactedPhone: "primary",
  //   contactedEmail: "primary",
  //   appointment: "success",
  //   rejected: "error",
  // };

  useEffect(() => {
    const setNewDocument = async () => {
      const orderDocs = collection(db, `${currentStudy}_PATIENTS`);

      const q = query(orderDocs, orderBy("created", "desc"));

      const newDoc = await getDocs(q);
      const parsedDocs = newDoc.docs.map((el) => ({ ...el.data(), id: el.id }));

      const newArray = [];

      for (const element of parsedDocs) {
        const itemObject = {
          firstName: element.firstName,
          lastName: element.lastName,
          email: element.email,
          phone: element.phone,
          referral: element.referralNumb,
          created: (
            <MDTypography variant="caption" color="text" fontWeight="medium" textAlign="left">
              {element.created.toDate().toLocaleDateString("en-us", {
                weekday: "long",
                year: "numeric",
                month: "short",
                day: "numeric",
              })}
            </MDTypography>
          ),
          contacted: (
            <MDTypography
              variant="caption"
              color={element.statusColor}
              fontWeight="medium"
              textAlign="left"
            >
              {element.lastModified.toDate().toLocaleDateString("en-us", {
                weekday: "long",
                year: "numeric",
                month: "short",
                day: "numeric",
              })}
            </MDTypography>
          ),
          source: element.source.overview,
          status: (
            <MDBox width="8rem">
              <MDBadge
                badgeContent={element.status}
                color={element.statusColor}
                size="sm"
                onClick={() => {
                  console.log("status change");
                  setUpdate(dispatch, element);
                  navigate("/update");
                }}
                sx={{ cursor: "pointer" }}
                container
              />
            </MDBox>
          ),
          view: (
            <MDBox color="text" px={2}>
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                onClick={() => {
                  setPatient(dispatch, element);
                  navigate("/patient");
                }}
              >
                view
              </MDButton>
            </MDBox>
          ),
        };
        newArray.push(itemObject);
      }
      setRowArray(newArray);
    };
    setNewDocument();

    const setCSVData = async () => {
      const orderDocs = collection(db, `${currentStudy}_PATIENTS`);

      const q = query(orderDocs, orderBy("created", "desc"));

      const newDoc = await getDocs(q);
      const parsedDocs = newDoc.docs.map((el) => ({ ...el.data(), id: el.id }));

      const dataArray = [["referral #", "first name", "last name", "email", "phone #", "source"]];

      for (const element of parsedDocs) {
        const csvData = [
          element.referralNumb,
          element.firstName,
          element.lastName,
          element.email,
          element.phone,
          element.source.details,
        ];

        dataArray.push(csvData);
      }
      setCSVArray(dataArray);
    };
    setCSVData();
  }, []);

  // const patient1 = {
  //   details: {
  //     firstName: "John",
  //     lastName: "Smith",
  //     email: "john@smith.com",
  //     phone: "123-456-7890",
  //   },
  //   contacted: [
  //     {
  //       type: "initial",
  //       method: "contacted by phone: 123-4567",
  //       date: "April 28th, 2022",
  //       icon: "add_ic_call_icon",
  //       last: false,
  //       color: "info",
  //     },
  //     {
  //       type: "followup",
  //       method: "contacted email: john@website.com",
  //       date: "May 1st, 2022",
  //       icon: "email_icon",
  //       last: true,
  //       color: "success",
  //     },
  //   ],
  // };
  // const patient2 = {
  //   details: {
  //     firstName: "Jane",
  //     lastName: "Smith",
  //     email: "jane@smith.com",
  //     phone: "123-456-7890",
  //   },
  //   contacted: [
  //     {
  //       type: "initial",
  //       method: "contacted by phone: 123-4567",
  //       date: "April 28th, 2022",
  //       icon: "add_ic_call_icon",
  //       last: false,
  //       color: "info",
  //     },
  //     {
  //       type: "followup",
  //       method: "contacted email: jane@website.com",
  //       date: "May 1st, 2022",
  //       icon: "email_icon",
  //       last: true,
  //       color: "success",
  //     },
  //   ],
  // };
  // const patient3 = {
  //   details: {
  //     firstName: "Jack",
  //     lastName: "Smith",
  //     email: "jack@smith.com",
  //     phone: "123-456-7890",
  //   },
  //   contacted: [
  //     {
  //       type: "initial",
  //       method: "contacted by phone: 123-4567",
  //       date: "April 28th, 2022",
  //       icon: "add_ic_call_icon",
  //       last: false,
  //       color: "info",
  //     },
  //     {
  //       type: "followup",
  //       method: "contacted email: jack@website.com",
  //       date: "May 1st, 2022",
  //       icon: "email_icon",
  //       last: true,
  //       color: "success",
  //     },
  //   ],
  // };
  // const patient4 = {
  //   details: {
  //     firstName: "Johann",
  //     lastName: "Smith",
  //     email: "johann@smith.com",
  //     phone: "123-456-7890",
  //   },
  //   contacted: [
  //     {
  //       type: "initial",
  //       method: "contacted by phone: 123-4567",
  //       date: "April 28th, 2022",
  //       icon: "add_ic_call_icon",
  //       last: false,
  //       color: "info",
  //     },
  //     {
  //       type: "followup",
  //       method: "contacted email: johann@website.com",
  //       date: "May 1st, 2022",
  //       icon: "email_icon",
  //       last: true,
  //       color: "success",
  //     },
  //   ],
  // };
  // const patient5 = {
  //   details: {
  //     firstName: "Steve",
  //     lastName: "Smith",
  //     email: "steve@smith.com",
  //     phone: "123-456-7890",
  //   },
  //   contacted: [
  //     {
  //       type: "initial",
  //       method: "contacted by phone: 123-4567",
  //       date: "April 28th, 2022",
  //       icon: "add_ic_call_icon",
  //       last: false,
  //       color: "info",
  //     },
  //     {
  //       type: "followup",
  //       method: "contacted email: steve@website.com",
  //       date: "May 1st, 2022",
  //       icon: "email_icon",
  //       last: true,
  //       color: "success",
  //     },
  //   ],
  // };

  // const elementArray = [
  //   {
  //     firstName: "John",
  //     lastName: "Smith",
  //     email: "john@smith.com",
  //     phone: "123-456-7890",
  //     referral: "QD100",
  //     created: (
  //       <MDTypography variant="caption" color="text" fontWeight="medium" textAlign="left">
  //         {newDate.toLocaleDateString("en-us", {
  //           weekday: "long",
  //           year: "numeric",
  //           month: "short",
  //           day: "numeric",
  //         })}
  //       </MDTypography>
  //     ),
  //     contacted: (
  //       <MDTypography variant="caption" color="text" fontWeight="medium" textAlign="left">
  //         Not yet contacted
  //       </MDTypography>
  //     ),
  //     status: (
  //       <MDBox width="8rem">
  //         <MDBadge
  //           badgeContent="uncontacted"
  //           color="warning"
  //           size="sm"
  //           onClick={() => {
  //             console.log("status change");
  //           }}
  //           sx={{ cursor: "pointer" }}
  //           container
  //         />
  //       </MDBox>
  //     ),
  //     view: (
  //       <MDBox color="text" px={2}>
  //         <MDButton
  //           variant="gradient"
  //           color="info"
  //           size="small"
  //           onClick={() => {
  //             setPatient(dispatch, patient1);
  //             navigate("/patient");
  //           }}
  //         >
  //           view
  //         </MDButton>
  //       </MDBox>
  //     ),
  //   },
  //   {
  //     firstName: "Jane",
  //     lastName: "Smith",
  //     email: "jane@smith.com",
  //     phone: "123-456-7890",
  //     referral: "QD101",
  //     created: (
  //       <MDTypography variant="caption" color="text" fontWeight="medium" textAlign="left">
  //         {newDate.toLocaleDateString("en-us", {
  //           weekday: "long",
  //           year: "numeric",
  //           month: "short",
  //           day: "numeric",
  //         })}
  //       </MDTypography>
  //     ),
  //     contacted: (
  //       <MDTypography variant="caption" color="text" fontWeight="medium" textAlign="left">
  //         Not yet contacted
  //       </MDTypography>
  //     ),
  //     status: (
  //       <MDBox width="8rem">
  //         <MDBadge
  //           badgeContent="uncontacted"
  //           color="warning"
  //           size="sm"
  //           onClick={() => {
  //             setUpdate(dispatch, patient2);
  //             navigate("/update");
  //           }}
  //           sx={{ cursor: "pointer" }}
  //           container
  //         />
  //       </MDBox>
  //     ),
  //     view: (
  //       <MDBox color="text" px={2}>
  //         <MDButton
  //           variant="gradient"
  //           color="info"
  //           size="small"
  //           onClick={() => {
  //             setPatient(dispatch, patient2);
  //             navigate("/patient");
  //           }}
  //         >
  //           view
  //         </MDButton>
  //       </MDBox>
  //     ),
  //   },
  //   {
  //     firstName: "Jack",
  //     lastName: "Smith",
  //     email: "jack@smith.com",
  //     phone: "123-456-7890",
  //     referral: "QD102",
  //     created: (
  //       <MDTypography variant="caption" color="text" fontWeight="medium" textAlign="left">
  //         {newDate.toLocaleDateString("en-us", {
  //           weekday: "long",
  //           year: "numeric",
  //           month: "short",
  //           day: "numeric",
  //         })}
  //       </MDTypography>
  //     ),
  //     contacted: (
  //       <MDTypography variant="caption" color="text" fontWeight="medium" textAlign="left">
  //         {newDate.toLocaleDateString("en-us", {
  //           weekday: "long",
  //           year: "numeric",
  //           month: "short",
  //           day: "numeric",
  //         })}
  //       </MDTypography>
  //     ),
  //     status: (
  //       <MDBox width="8rem">
  //         <MDBadge
  //           badgeContent="uncontacted"
  //           color="warning"
  //           size="sm"
  //           onClick={() => {
  //             console.log("status change");
  //           }}
  //           sx={{ cursor: "pointer" }}
  //           container
  //         />
  //       </MDBox>
  //     ),
  //     view: (
  //       <MDBox color="text" px={2}>
  //         <MDButton
  //           variant="gradient"
  //           color="info"
  //           size="small"
  //           onClick={() => {
  //             setPatient(dispatch, patient3);
  //             navigate("/patient");
  //           }}
  //         >
  //           view
  //         </MDButton>
  //       </MDBox>
  //     ),
  //   },
  //   {
  //     firstName: "Johann",
  //     lastName: "Smith",
  //     email: "johann@smith.com",
  //     phone: "123-456-7890",
  //     referral: "QD103",
  //     created: (
  //       <MDTypography variant="caption" color="text" fontWeight="medium" textAlign="left">
  //         {newDate.toLocaleDateString("en-us", {
  //           weekday: "long",
  //           year: "numeric",
  //           month: "short",
  //           day: "numeric",
  //         })}
  //       </MDTypography>
  //     ),
  //     contacted: (
  //       <MDTypography variant="caption" color="text" fontWeight="medium" textAlign="left">
  //         {newDate.toLocaleDateString("en-us", {
  //           weekday: "long",
  //           year: "numeric",
  //           month: "short",
  //           day: "numeric",
  //         })}
  //       </MDTypography>
  //     ),
  //     status: (
  //       <MDBox width="8rem">
  //         <MDBadge badgeContent="rejected" color="error" size="sm" container />
  //       </MDBox>
  //     ),
  //     view: (
  //       <MDBox color="text" px={2}>
  //         <MDButton
  //           variant="gradient"
  //           color="info"
  //           size="small"
  //           onClick={() => {
  //             setPatient(dispatch, patient4);
  //             navigate("/patient");
  //           }}
  //         >
  //           view
  //         </MDButton>
  //       </MDBox>
  //     ),
  //   },
  //   {
  //     firstName: "Steve",
  //     lastName: "Smith",
  //     email: "steve@smith.com",
  //     phone: "123-456-7890",
  //     referral: "QD104",
  //     created: (
  //       <MDTypography variant="caption" color="text" fontWeight="medium" textAlign="left">
  //         {newDate.toLocaleDateString("en-us", {
  //           weekday: "long",
  //           year: "numeric",
  //           month: "short",
  //           day: "numeric",
  //         })}
  //       </MDTypography>
  //     ),
  //     contacted: (
  //       <MDTypography variant="caption" color="text" fontWeight="medium" textAlign="left">
  //         {newDate.toLocaleDateString("en-us", {
  //           weekday: "long",
  //           year: "numeric",
  //           month: "short",
  //           day: "numeric",
  //         })}
  //       </MDTypography>
  //     ),
  //     status: (
  //       <MDBox width="8rem">
  //         <MDBadge badgeContent="appointment made" color="success" size="sm" container />
  //       </MDBox>
  //     ),
  //     view: (
  //       <MDBox color="text" px={2}>
  //         <MDButton
  //           variant="gradient"
  //           color="info"
  //           size="small"
  //           onClick={() => {
  //             setPatient(dispatch, patient5);
  //             navigate("/patient");
  //           }}
  //         >
  //           view
  //         </MDButton>
  //       </MDBox>
  //     ),
  //   },
  // ];

  return {
    columns: [
      { Header: "view patient", accessor: "view", width: "10%", align: "left" },
      { Header: "status", accessor: "status", width: "10%", align: "left" },
      { Header: "source", accessor: "source", width: "10%", align: "left" },
      { Header: "referral #", accessor: "referral", width: "10%", align: "left" },
      { Header: "last name", accessor: "lastName", width: "10%", align: "left" },
      { Header: "first name", accessor: "firstName", width: "10%", align: "left" },
      { Header: "phone number", accessor: "phone", width: "10%", align: "left" },
      { Header: "email", accessor: "email", width: "10%", align: "left" },
      { Header: "created on", accessor: "created", width: "10%", align: "left" },
      { Header: "last modified", accessor: "contacted", width: "10%", align: "left" },
    ],

    rows: rowArray,

    CSV: CSVArray,
  };
}
