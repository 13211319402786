// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Component Imports
import CartCard from "examples/Cards/BlogCards/CartCard";

// Custom styles for the Configurator
import ConfiguratorRoot from "examples/Configurator/ConfiguratorRoot";

// Material Dashboard 2 React context
import { useMaterialUIController, setOpenConfigurator, setCart, setEmptyCart } from "context";

function Configurator() {
  const [controller, dispatch] = useMaterialUIController();
  const { openConfigurator, darkMode, cart, emptyCart } = controller;

  const removeFromCart = (el) => {
    let hardCopy = [...cart];
    hardCopy = hardCopy.filter((cartItem) => cartItem.id !== el);
    const localStorageCopy = JSON.stringify(hardCopy);
    setCart(dispatch, hardCopy);
    window.localStorage.setItem("cart", localStorageCopy);

    const cartLength = JSON.parse(window.localStorage.getItem("cart")).length;
    if (cartLength === 0) setEmptyCart(dispatch, true);
  };

  const cartItems = cart.map((el) => (
    <CartCard
      key={el.id}
      id={el.id}
      image={el.image}
      title={el.title}
      cartQuantity={el.cartQuantity}
      remove={() => {
        removeFromCart(el.id);
      }}
    />
  ));

  const handleCloseConfigurator = () => setOpenConfigurator(dispatch, false);

  return (
    <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="baseline" pt={4} px={3}>
        <MDBox>
          <MDTypography variant="h5">Cart</MDTypography>
          <MDTypography variant="body2" color="text">
            QD Solutions
          </MDTypography>
        </MDBox>

        <Icon
          sx={({ typography: { size }, palette: { dark, white } }) => ({
            fontSize: `${size.lg} !important`,
            color: darkMode ? white.main : dark.main,
            stroke: "currentColor",
            strokeWidth: "2px",
            cursor: "pointer",
            transform: "translateY(5px)",
          })}
          onClick={handleCloseConfigurator}
        >
          close
        </Icon>
      </MDBox>
      <Divider />
      <Divider />
      <MDBox pb={3} px={3}>
        {emptyCart && <MDTypography variant="caption">Your cart is empty!</MDTypography>}
        {cartItems}
        <MDBox mt={3} mb={2}>
          <Link to="/checkout">
            <MDButton color="info" onClick={handleCloseConfigurator}>
              checkout
            </MDButton>
          </Link>
        </MDBox>
      </MDBox>
    </ConfiguratorRoot>
  );
}

export default Configurator;
