/* eslint-disable no-restricted-syntax */

// react imports
import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// react-CSV import
import { CSVLink } from "react-csv";

// Firebase imports
import { getDoc, doc, collection, getDocs } from "firebase/firestore";
import { db } from "../../Firebase";

function Overview() {
  const [totalsData, setTotalsData] = useState({});
  // const [zipData, setZipData] = useState([]);
  const [CSVArray, setCSVArray] = useState([]);

  useEffect(() => {
    const getDocData = async () => {
      const totalsDocRef = doc(db, "PageViewTotals", "total_view_counter");
      const totalsDoc = await getDoc(totalsDocRef);
      console.log(totalsDoc.data());
      setTotalsData(totalsDoc.data());
    };
    getDocData();

    const setCSVData = async () => {
      const zipDocs = collection(db, "Zipcodes");

      // const q = query(orderDocs, orderBy("created", "desc"));

      const newDoc = await getDocs(zipDocs);
      const parsedDocs = newDoc.docs.map((el) => ({ ...el.data(), id: el.id }));

      const dataArray = [["Response", "language", "time", "postal code"]];

      for (const element of parsedDocs) {
        const csvData = [
          element.formstate_reached,
          element.lang,
          element.timestamp.toDate().toLocaleDateString("en-us", {
            weekday: "long",
            year: "numeric",
            month: "short",
            day: "numeric",
          }),
          element.zipcode,
        ];

        dataArray.push(csvData);
      }
      setCSVArray(dataArray);
    };
    setCSVData();
  }, []);

  // const totalsData = data.map(() => (
  // <ComplexStatisticsCard
  //   color="primary"
  //   icon="person_add"
  //   title="Total Views"
  //   count={data.total_views_count_Jul_2022}
  //   percentage={{
  //     color: "success",
  //     amount: "+55%",
  //     label: "over last week",
  //   }}
  // />
  // ));

  // const getZipData = async () => {
  //   const zipDocs = collection(db, "Zipcodes");

  //   const zips = await getDocs(zipDocs);

  //   const parsedZips = zips.docs.map((el) => ({ ...el.data(), id: el.id }));
  //   console.log(parsedZips);
  //   // setZipData(parsedZips);
  // };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="calendar_month"
                title="Total Views July 2022"
                count={totalsData.total_views_count_Jul_2022}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="calendar_month"
                title="Total Views June 2022"
                count={totalsData.total_views_count_Jun_2022}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="calendar_month"
                title="Total Views May 2022"
                count={totalsData.total_views_count_May_2022}
              />
            </MDBox>
          </Grid>
        </Grid>
        <Grid container spacing={3} my={2}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="co_present"
                title="Unique Views July 2022"
                count={totalsData.unique_views_count_Jul_2022}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="co_present"
                title="Unique Views June 2022"
                count={totalsData.unique_views_count_Jun_2022}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="co_present"
                title="Unique Views May 2022"
                count={totalsData.unique_views_count_May_2022}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8}>
              <CSVLink data={CSVArray}>
                <MDButton variant="contained">Export Zip Data</MDButton>
              </CSVLink>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
