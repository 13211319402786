/* eslint-disable no-restricted-syntax */

// react imports
import { useState } from "react";

// react-routers components
import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// firebase imports
import { doc, setDoc, collection } from "firebase/firestore";

// @mui material components
import { Divider, Card, NativeSelect } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import QDCartAvatar from "components/QDCartAvatar";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// Material Dashboard 2 React context
import { useMaterialUIController, setCart } from "context";

// uuid import
import uuid from "react-uuid";

// firebase db import
import { db } from "../../../Firebase";

function ProfilesList({ header, profiles, shadow }) {
  const [controller, dispatch] = useMaterialUIController();
  const { cart, emptyCart } = controller;

  const [address, setAddress] = useState("");
  const [addressError, setAddressError] = useState(false);
  const [city, setCity] = useState("");
  const [cityError, setCityError] = useState(false);
  const [zip, setZip] = useState("");
  const [zipError, setZipError] = useState(false);
  const [cartError, setCartError] = useState(false);

  const [editActive, setEditActive] = useState(0);
  const [value, setValue] = useState(0);

  const checkoutWithShipping = async (el) => {
    if (zip === "") {
      setZipError(true);
    } else {
      setZipError(false);
    }

    if (city === "") {
      setCityError(true);
    } else {
      setCityError(false);
    }

    if (address === "") {
      setAddressError(true);
    } else {
      setAddressError(false);
    }

    if (cart.length === 0) {
      setCartError(true);
    } else {
      setCartError(false);
    }

    if (address && city && zip && cart.length !== 0) {
      const today = new Date();
      const orderID = uuid();

      const itemsArray = [];
      cart.map((object) =>
        itemsArray.push({ image: object.image, title: object.title, quantity: object.cartQuantity })
      );

      const docData = {
        orderId: orderID,
        date_created: today,
        date_modified: today,
        street: el.address,
        city: el.city,
        zip: el.zip,
        items: itemsArray,
        status: "processing",
        status_color: "secondary",
      };

      // console.log(docData);

      const docCollection = await doc(collection(db, "STOREFRONT_ORDERS"));
      await setDoc(docCollection, docData);

      // Query example for Internal Portal
      // const newDoc = await getDocs(collection(db, "STOREFRONT_ORDERS"));
      // const q = query(collection(db, "STOREFRONT_ORDERS"), where("zip", "==", "78628"));
      // const querySnapshot = await getDocs(q);
      // const parsedDocs = querySnapshot.docs.map((x) => ({ ...x.data(), id: x.id }));
      // console.log(parsedDocs);
      // const parsedItems = parsedDocs[0].item_image_urls;
      // console.log(parsedItems);

      // for (const images of parsedItems) {
      //   console.log(images);
      // }

      window.localStorage.removeItem("cart");
      setCart(dispatch, []);
      setAddress("");
      setZip("");
      setCity("");
    } else {
      console.log("fill in that info!");
    }
  };

  function editItem(item) {
    setEditActive(item);
  }

  function saveItem(el) {
    if (el.newCartQuantity === 0) {
      setEditActive(0);
    } else {
      const updatedCartItem = cart.map((x) =>
        x.id === el.id ? { ...x, cartQuantity: el.newCartQuantity } : x
      );
      let hardCopy = [...cart];
      hardCopy = hardCopy.filter((cartItem) => cartItem.id !== el.id);
      setCart(dispatch, hardCopy);
      setCart(dispatch, updatedCartItem);
      window.localStorage.setItem("cart", JSON.stringify(hardCopy));
      window.localStorage.setItem("cart", JSON.stringify(updatedCartItem));
      setEditActive(0);
    }
  }

  const renderProfiles = profiles.map(({ id, image, title, cartQuantity }) => (
    <MDBox key={id} component="li" display="flex" alignItems="center" py={1} mb={1}>
      <MDBox mr={2}>
        <QDCartAvatar src={image} alt="something here" shadow="md" variant="square" />
      </MDBox>
      <MDBox display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
        <MDTypography variant="button" fontWeight="medium">
          {title}
        </MDTypography>
        <MDTypography variant="caption" color="text">
          Quantity:{" "}
          {editActive === id ? (
            <NativeSelect
              onChange={(e) => {
                setValue(e.target.value);
              }}
              defaultValue={0}
              inputProps={{
                name: "quantity",
                id: "uncontrolled-native",
              }}
            >
              <option value={0}> </option>
              <option value={1}>1</option>
              <option value={5}>5</option>
              <option value={10}>10</option>
            </NativeSelect>
          ) : (
            cartQuantity
          )}
        </MDTypography>
      </MDBox>
      <MDBox ml="auto">
        {editActive === id ? (
          <MDButton
            component="button"
            onClick={() => {
              const newCartQuantity = value * 1;
              saveItem({ id, newCartQuantity, title, image });
            }}
            variant="text"
            color="info"
          >
            save
          </MDButton>
        ) : (
          <MDButton
            component="button"
            onClick={() => {
              editItem(id);
            }}
            variant="text"
            color="info"
          >
            edit
          </MDButton>
        )}
      </MDBox>
    </MDBox>
  ));

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <MDBox pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {header}
        </MDTypography>
        {emptyCart && <MDTypography variant="caption">Your cart is empty!</MDTypography>}
      </MDBox>
      <MDBox p={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {renderProfiles}
        </MDBox>
        <Divider />
        <MDBox p={2}>
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            shipping info
          </MDTypography>
          <MDBox p={2}>
            {addressError && (
              <MDTypography variant="caption" color="error">
                Please enter an address.
              </MDTypography>
            )}
            <MDInput
              type="text"
              label="Address"
              value={address}
              onChange={(e) => {
                setAddress(e.target.value);
              }}
              style={{ width: "100%" }}
            />
          </MDBox>
          <MDBox p={2}>
            {cityError && (
              <MDTypography variant="caption" color="error">
                Please enter a city.
              </MDTypography>
            )}
            <MDInput
              type="text"
              label="City"
              value={city}
              onChange={(e) => {
                setCity(e.target.value);
              }}
              style={{ width: "100%" }}
            />
          </MDBox>
          <MDBox p={2}>
            {zipError && (
              <MDTypography variant="caption" color="error">
                Please enter a postal code.
              </MDTypography>
            )}
            <MDInput
              type="text"
              label="Postal Code"
              value={zip}
              onChange={(e) => {
                setZip(e.target.value);
              }}
              style={{ width: "100%" }}
            />
          </MDBox>
          <MDBox px={3} pt={1}>
            {cartError && (
              <MDTypography variant="caption" color="error">
                Your cart is empty!
              </MDTypography>
            )}
          </MDBox>
          <MDBox p={3}>
            <Link to="/checkout">
              <MDButton
                color="info"
                onClick={() => {
                  checkoutWithShipping({ address, city, zip });
                }}
              >
                checkout
              </MDButton>
            </Link>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default props for the ProfilesList
ProfilesList.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfilesList
ProfilesList.propTypes = {
  header: PropTypes.string.isRequired,
  profiles: PropTypes.arrayOf(PropTypes.object).isRequired,
  shadow: PropTypes.bool,
};

export default ProfilesList;
