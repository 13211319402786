// @mui imports
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";

// Data
import data from "layouts/orders/components/Projects/data";

import { CSVLink } from "react-csv";

function Projects() {
  const { columns, rows, CSV } = data();

  // const csvData = [
  //   ["firstname", "lastname", "email"],
  //   ["Ahmed", "Tomi", "ah@smthing.co.com"],
  //   ["Raed", "Labes", "rl@smthing.co.com"],
  //   ["Yezzi", "Min l3b", "ymin@cocococo.com"],
  // ];

  return (
    <Card>
      <MDBox px={2}>
        <CSVLink data={CSV}>
          <MDButton variant="gradient" color="info" size="small" sx={{ marginLeft: "1rem" }}>
            export csv
          </MDButton>
        </CSVLink>
      </MDBox>
      <MDBox>
        <DataTable
          table={{ columns, rows }}
          showTotalEntries={false}
          isSorted
          noEndBorder
          entriesPerPage
          canSearch
        />
      </MDBox>
    </Card>
  );
}

export default Projects;
