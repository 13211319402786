/* eslint-disable no-restricted-syntax */

// react imports
import { createContext, useContext, useReducer, useMemo } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React main context
const MaterialUI = createContext();

// Setting custom name for the context which is visible on react dev tools
MaterialUI.displayName = "MaterialUIContext";

// Material Dashboard 2 React reducer
function reducer(state, action) {
  switch (action.type) {
    case "MINI_SIDENAV": {
      return { ...state, miniSidenav: action.value };
    }
    case "TRANSPARENT_SIDENAV": {
      return { ...state, transparentSidenav: action.value };
    }
    case "WHITE_SIDENAV": {
      return { ...state, whiteSidenav: action.value };
    }
    case "SIDENAV_COLOR": {
      return { ...state, sidenavColor: action.value };
    }
    case "TRANSPARENT_NAVBAR": {
      return { ...state, transparentNavbar: action.value };
    }
    case "FIXED_NAVBAR": {
      return { ...state, fixedNavbar: action.value };
    }
    case "OPEN_CONFIGURATOR": {
      return { ...state, openConfigurator: action.value };
    }
    case "DIRECTION": {
      return { ...state, direction: action.value };
    }
    case "LAYOUT": {
      return { ...state, layout: action.value };
    }
    case "DARKMODE": {
      return { ...state, darkMode: action.value };
    }
    case "CART": {
      return { ...state, cart: action.value };
    }
    case "EMPTY_CART": {
      return { ...state, emptyCart: action.value };
    }
    case "DISPLAY_NAME": {
      return { ...state, displayName: action.value };
    }
    case "ORDERS": {
      return { ...state, orders: action.value };
    }
    case "PARTICIPANT": {
      return { ...state, patient: action.value };
    }
    case "UPDATE": {
      return { ...state, update: action.value };
    }
    case "USER_STUDIES": {
      return { ...state, userStudies: action.value };
    }
    case "ACTIVE_NAV_LINK": {
      return { ...state, activeNavLink: action.value };
    }
    case "CURRENT_STUDY": {
      return { ...state, currentStudy: action.value };
    }
    case "STUDY_PAGE": {
      return { ...state, studyPage: action.value };
    }
    case "REMEMBER_ME": {
      return { ...state, rememberMe: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function setInitialLocalStorageCartState() {
  const localStorageCart = window.localStorage.getItem("cart");
  if (localStorageCart === null || localStorageCart === undefined) {
    return [];
  }
  return JSON.parse(localStorageCart);
}

function checkCartIsEmpty() {
  const localStorageCart = window.localStorage.getItem("cart");
  if (localStorageCart === null || localStorageCart === undefined) {
    return true;
  }
  if (JSON.parse(localStorageCart).length > 0) {
    return false;
  }
  return true;
}

// Material Dashboard 2 React context provider
function MaterialUIControllerProvider({ children }) {
  const initialState = {
    miniSidenav: false,
    transparentSidenav: false,
    whiteSidenav: false,
    sidenavColor: "info",
    transparentNavbar: true,
    fixedNavbar: true,
    openConfigurator: false,
    direction: "ltr",
    layout: "loading",
    darkMode: false,
    cart: setInitialLocalStorageCartState(),
    emptyCart: checkCartIsEmpty(),
    displayName: "",
    orders: [],
    patient: [],
    update: [],
    userStudies: [],
    activeNavLink: "overview",
    currentStudy: "Warbler_Q123K5",
    studyPage: {},
    rememberMe: false,
  };

  const [controller, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  return <MaterialUI.Provider value={value}>{children}</MaterialUI.Provider>;
}

// Material Dashboard 2 React custom hook for using context
function useMaterialUIController() {
  const context = useContext(MaterialUI);

  if (!context) {
    throw new Error(
      "useMaterialUIController should be used inside the MaterialUIControllerProvider."
    );
  }

  return context;
}

// Typechecking props for the MaterialUIControllerProvider
MaterialUIControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setMiniSidenav = (dispatch, value) => dispatch({ type: "MINI_SIDENAV", value });
const setTransparentSidenav = (dispatch, value) => dispatch({ type: "TRANSPARENT_SIDENAV", value });
const setWhiteSidenav = (dispatch, value) => dispatch({ type: "WHITE_SIDENAV", value });
const setSidenavColor = (dispatch, value) => dispatch({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar = (dispatch, value) => dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar = (dispatch, value) => dispatch({ type: "FIXED_NAVBAR", value });
const setOpenConfigurator = (dispatch, value) => dispatch({ type: "OPEN_CONFIGURATOR", value });
const setDirection = (dispatch, value) => dispatch({ type: "DIRECTION", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
const setDarkMode = (dispatch, value) => dispatch({ type: "DARKMODE", value });
const setCart = (dispatch, value) => dispatch({ type: "CART", value });
const setEmptyCart = (dispatch, value) => dispatch({ type: "EMPTY_CART", value });
const setDisplayName = (dispatch, value) => dispatch({ type: "DISPLAY_NAME", value });
const setOrderCart = (dispatch, value) => dispatch({ type: "ORDERS", value });
const setPatient = (dispatch, value) => dispatch({ type: "PARTICIPANT", value });
const setUpdate = (dispatch, value) => dispatch({ type: "UPDATE", value });
const setUserStudies = (dispatch, value) => dispatch({ type: "USER_STUDIES", value });
const setActiveNavLink = (dispatch, value) => dispatch({ type: "ACTIVE_NAV_LINK", value });
const setCurrentStudy = (dispatch, value) => dispatch({ type: "CURRENT_STUDY", value });
const setStudyPage = (dispatch, value) => dispatch({ type: "STUDY_PAGE", value });
const setRememberMe = (dispatch, value) => dispatch({ type: "REMEMBER_ME", value });

export {
  MaterialUIControllerProvider,
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setDirection,
  setLayout,
  setDarkMode,
  setCart,
  setEmptyCart,
  setDisplayName,
  setOrderCart,
  setPatient,
  setUpdate,
  setUserStudies,
  setActiveNavLink,
  setCurrentStudy,
  setStudyPage,
  setRememberMe,
};
