// react imports
import { useState } from "react";

// react-router imports
import { Link } from "react-router-dom";

// @mui material components
import { Card, Icon } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-reset-cover.jpeg";

// Firebase imports
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

function Cover() {
  const auth = getAuth();

  const [sent, setSent] = useState(false);
  const [email, setEmail] = useState("");

  return (
    <CoverLayout coverHeight="50vh" image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <Link to="authentication/sign-in">
            <MDTypography display="block" variant="button" color="white" my={1}>
              <Icon>arrow_back_ios</Icon> Return to Sign-In
            </MDTypography>
          </Link>
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            {sent ? "Message sent!" : "You will receive an e-mail to reset your password"}
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            {sent ? (
              <MDTypography display="block" variant="button" my={1}>
                Message sent! You will recieve a reset password email shortly
              </MDTypography>
            ) : (
              <>
                <MDBox mb={4}>
                  <MDInput
                    type="email"
                    label="Email"
                    variant="standard"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    fullWidth
                  />
                </MDBox>
                <MDBox mt={6} mb={1}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      sendPasswordResetEmail(auth, email)
                        .then(() => {
                          setSent(true);
                        })
                        .catch((error) => {
                          const errorCode = error.code;
                          const errorMessage = error.message;
                          console.log(errorCode, errorMessage);
                        });
                    }}
                    fullWidth
                  >
                    reset
                  </MDButton>
                </MDBox>
              </>
            )}
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
