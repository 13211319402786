// react imports
import { useState, useEffect } from "react";

// react router imports
import { useNavigate } from "react-router-dom";

// @mui material components
import { Grid, Card, NativeSelect, Icon } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import TimelineItem from "examples/Timeline/TimelineItem";

// notistack imports
import { useSnackbar } from "notistack";

// uuid import
import uuid from "react-uuid";

// Material Dashboard 2 React context
import { useMaterialUIController, setDisplayName } from "context";

// Firebase imports
import { doc, getDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { db } from "../../Firebase";

function Update() {
  const [controller, dispatch] = useMaterialUIController();
  const { update, displayName } = controller;

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [status, setStatus] = useState("default");

  const newDate = new Date();
  const stringDate = newDate.toLocaleDateString("en-us", {
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
  });

  useEffect(() => {
    const getUserDetails = async () => {
      const localStorageName = window.localStorage.getItem("userName");

      if (localStorageName === undefined || localStorageName === null) {
        const authUser = getAuth().currentUser;
        const userDoc = doc(db, "USERS", authUser.email);
        const userDetails = await getDoc(userDoc);
        window.localStorage.setItem("userName", userDetails.data().firstName);
      }

      setDisplayName(dispatch, localStorageName);
    };
    getUserDetails();
  }, [displayName]);

  const contactedOptions = {
    default: "Please choose a status",
    new: "Patient is New",
    leftMessage: "Left Message",
    visitScheduled: "Study Visit Scheduled",
    failedScreening: "Failed at Screening",
    failedPhone: "Failed On Phone Visit",
    noLongerInterested: "No Longer Interested",
    randomized: "randomized",
  };

  const contactedOptionDetails = {
    new: `${update.firstName} is a new patient and needs to be contacted`,
    leftMessage: `${displayName} left message with ${update.firstName}`,
    visitScheduled: `Study Visit Scheduled with ${update.firstName}`,
    failedScreening: `${update.firstName} failed screening with ${displayName}`,
    failedPhone: `${update.firstName} failed on phone interview with ${displayName}`,
    noLongerInterested: `${update.firstName} is no longer interested`,
    randomized: `${update.firstName} ${update.lastName} has been randomized on ${stringDate} by ${displayName}`,
  };

  const contactedOptionColors = {
    default: "info",
    new: "warning",
    leftMessage: "info",
    visitScheduled: "info",
    failedScreening: "error",
    failedPhone: "error",
    noLongerInterested: "error",
    randomized: "success",
  };

  const contactedOptionIcons = {
    default: "add",
    new: "accessibility_new",
    leftMessage: "email",
    visitScheduled: "tour",
    failedScreening: "sms_failed",
    failedPhone: "crisis_alert",
    noLongerInterested: "do_not_disturb",
    randomized: "thumb_up",
  };

  const saveStatus = async () => {
    const date = new Date();
    const patientDocRef = doc(db, "STUDY_ID_PARTICIPANTS", update.id);

    const isLastBoolean = contactedOptions[status] === "randomized";

    const timelineItem = {
      id: uuid().substring(0, 10),
      timeStamp: date,
      status: contactedOptions[status],
      statusTitle: contactedOptions[status],
      statusDetails: contactedOptionDetails[status],
      statusColor: contactedOptionColors[status],
      statusIcon: contactedOptionIcons[status],
      isLast: isLastBoolean,
    };

    const variant = "success";
    enqueueSnackbar(`${update.firstName}'s status has been updated!`, {
      variant,
      autoHideDuration: 2000,
    });

    await updateDoc(patientDocRef, {
      lastModified: date,
      status: contactedOptions[status],
      statusDetails: contactedOptionDetails[status],
      statusColor: contactedOptionColors[status],
      timeline: arrayUnion(timelineItem),
    });

    navigate("/");
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={6}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Status Update
                </MDTypography>
              </MDBox>
              <MDBox p={2}>
                <MDBox py={2}>
                  <MDButton
                    variant="outlined"
                    color="secondary"
                    size="small"
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    <Icon>arrow_back_ios</Icon>
                  </MDButton>
                </MDBox>
                <MDTypography variant="body1" color="dark">
                  {update.firstName} {update.lastName}
                </MDTypography>
                <MDBox py={2}>
                  <MDTypography variant="body2" color="dark" sx={{ display: "inline" }}>
                    Select Status:{" "}
                  </MDTypography>
                  <NativeSelect
                    onChange={(e) => {
                      setStatus(e.target.value);
                    }}
                    defaultValue="none"
                    inputProps={{
                      name: "status select",
                      id: "uncontrolled-native",
                    }}
                    sx={{ width: "100%" }}
                  >
                    <option value="new">New</option>
                    <option value="leftMessage">Left Message</option>
                    <option value="visitScheduled">Study Visit Scheduled</option>
                    <option value="failedScreening">Failed At Screening</option>
                    <option value="failedPhone">Failed On Phone Visit</option>
                    <option value="noLongerInterested">No Longer Interested</option>
                    <option value="randomized">Randomized</option>
                  </NativeSelect>
                </MDBox>
                <MDBox borderRadius={5} bgColor="light" p={2}>
                  <TimelineItem
                    color={contactedOptionColors[status]}
                    icon={contactedOptionIcons[status]}
                    title={contactedOptions[status]}
                    dateTime={stringDate}
                    description={contactedOptionDetails[status]}
                    lastItem
                  />
                </MDBox>
                <MDBox py={2}>
                  <MDButton variant="outlined" color="secondary" size="small" onClick={saveStatus}>
                    Save Status
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Update;
