// react imports
import { useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import Card from "@mui/material/Card";
import MuiLink from "@mui/material/Link";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React context
import { useMaterialUIController, setCart, setEmptyCart } from "context";

function ShoppingCard({
  id,
  image,
  title,
  dimensions,
  description,
  quantity,
  quantityOptions,
  action,
}) {
  const [controller, dispatch] = useMaterialUIController();
  const { cart } = controller;

  const [value, setValue] = useState("");
  const [readMore, setReadMore] = useState(false);

  const addToCart = (el) => {
    setEmptyCart(dispatch, false);
    if (cart.some((e) => e.id === el.id)) {
      const cartItemQuantity = cart.find((obj) => obj.id === el.id);
      const brandNewValue = cartItemQuantity.cartQuantity + el.cartQuantity;
      const updatedCartItem = cart.map((x) =>
        x.id === id ? { ...x, cartQuantity: brandNewValue } : x
      );
      let hardCopy = [...cart];
      hardCopy = hardCopy.filter((cartItem) => cartItem.id !== el.id);
      setCart(dispatch, hardCopy);
      setCart(dispatch, updatedCartItem);
      window.localStorage.setItem("cart", JSON.stringify(hardCopy));
      window.localStorage.setItem("cart", JSON.stringify(updatedCartItem));
    } else {
      const addItem = [...cart, el];
      setCart(dispatch, [...cart, el]);
      window.localStorage.setItem("cart", JSON.stringify(addItem));
    }
  };

  return (
    <Card>
      <MDBox position="relative" borderRadius="lg" mt={-3} mx={2}>
        <MDBox
          component="img"
          src={image}
          alt={title}
          borderRadius="lg"
          shadow="md"
          width="100%"
          height="100%"
          position="relative"
          zIndex={1}
        />
        <MDBox
          borderRadius="lg"
          shadow="md"
          width="100%"
          height="100%"
          position="absolute"
          left={0}
          top="3%"
          sx={{
            backgroundImage: `url(${image})`,
            transform: "scale(0.94)",
            filter: "blur(12px)",
            backgroundSize: "cover",
          }}
        />
      </MDBox>
      <MDBox p={3}>
        <MDTypography display="inline" variant="h3" textTransform="capitalize" fontWeight="bold">
          {title}
        </MDTypography>
        <MDBox mt={2} mb={3}>
          <MDTypography variant="body2" component="p" color="text">
            {dimensions}
          </MDTypography>
          <MDTypography variant="body2" component="p" color="text">
            {readMore ? description : `${description.slice(0, 100)}...`}
            <MDButton
              component="button"
              onClick={() => {
                setReadMore(!readMore);
              }}
              variant="text"
              color="info"
            >
              read more
            </MDButton>
          </MDTypography>
          {action.type === "external" ? (
            <MuiLink href={action.route} target="_blank" rel="noreferrer">
              <MDButton color={action.color ? action.color : "dark"}>{action.label}</MDButton>
            </MuiLink>
          ) : (
            <FormControl fullWidth sx={{ display: "inline" }}>
              <MDTypography variant="body2" component="p" color="text" sx={{ display: "inline" }}>
                {quantity}
              </MDTypography>
              <NativeSelect
                onChange={(e) => {
                  setValue(e.target.value);
                }}
                defaultValue={quantityOptions[0]}
                inputProps={{
                  name: "quantity",
                  id: "uncontrolled-native",
                }}
              >
                {quantityOptions.map((x) => (
                  <option value={x}>{x}</option>
                ))}
              </NativeSelect>
              <MDBox mt={2} mb={3}>
                <MDButton
                  color={action.color ? action.color : "dark"}
                  onClick={() => {
                    const cartQuantity = value * 1;
                    if (cartQuantity === 0) {
                      setValue(quantityOptions[0]);
                    }
                    console.log(quantityOptions[0]);
                    addToCart({ id, cartQuantity, title, image });
                  }}
                >
                  add to cart
                </MDButton>
              </MDBox>
            </FormControl>
          )}
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Typechecking props for the SimpleBlogCard
ShoppingCard.propTypes = {
  id: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  dimensions: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  quantity: PropTypes.string.isRequired,
  quantityOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
      "default",
    ]),
    label: PropTypes.string.isRequired,
  }).isRequired,
};

export default ShoppingCard;
