// react imports
import { useEffect } from "react";

// react router imports
import { useNavigate } from "react-router-dom";

// @mui material components
import { Grid, Card, Icon } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// import MDAvatar from "components/MDAvatar";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Material Dashboard 2 React example components
import TimelineItem from "examples/Timeline/TimelineItem";

// Material Dashboard 2 React context
import { useMaterialUIController, setActiveNavLink } from "context";

function Patient() {
  const [controller, dispatch] = useMaterialUIController();
  const { patient, currentStudy } = controller;

  const navigate = useNavigate();

  //   const patientArray = [patient.name, patient.phone];
  // const patientArray = patient.contacted.map((x) => [
  //   x.type,
  //   x.method,
  //   x.date,
  //   x.icon,
  //   x.last,
  //   x.color,
  // ]);

  const timelineMap = patient.timeline.map((el) => (
    <TimelineItem
      key={el.id}
      color={el.statusColor}
      icon={el.statusIcon}
      title={el.statusTitle}
      dateTime={el.timeStamp.toDate().toLocaleDateString("en-us", {
        weekday: "long",
        year: "numeric",
        month: "short",
        day: "numeric",
      })}
      lastItem={el.isLast}
      description={el.statusDetails}
    />
  ));

  useEffect(() => {
    console.log(patient);
  }, [patient]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={6}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  {patient.firstName}
                </MDTypography>
              </MDBox>
              <Card sx={{ height: "100%", boxShadow: "none" }}>
                <MDBox pb={2} px={2}>
                  <MDBox py={2}>
                    <MDButton
                      variant="outlined"
                      color="secondary"
                      size="small"
                      onClick={() => {
                        setActiveNavLink(dispatch, currentStudy);
                        navigate(`/${currentStudy}`);
                      }}
                    >
                      <Icon>arrow_back_ios</Icon>
                    </MDButton>
                  </MDBox>
                  <MDBox py={2} px={2} mb={2} borderRadius={5} bgColor="light">
                    <MDTypography variant="h6">Contact Information</MDTypography>
                    <MDTypography variant="body2" sx={{ fontSize: ".75rem" }}>
                      <strong>Name: </strong> {patient.firstName} {patient.lastName}
                      <br />
                      <strong>Phone #: </strong> {patient.phone}
                      <br />
                      <strong>Email: </strong>
                      {patient.email}
                      <br />
                    </MDTypography>
                  </MDBox>
                  <MDBox py={2} px={2} my={2} borderRadius={5} bgColor="light">
                    <MDTypography variant="h6">Patient Information</MDTypography>
                    <MDTypography variant="body2" sx={{ fontSize: ".75rem" }}>
                      <strong>Referral #: </strong>
                      {patient.referralNumb}
                      <br />
                      <strong>Created: </strong>
                      {patient.created.toDate().toLocaleDateString("en-us", {
                        weekday: "long",
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })}
                      <br />
                      <strong>Last Modified: </strong>
                      {patient.lastModified.toDate().toLocaleDateString("en-us", {
                        weekday: "long",
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })}
                      <br />
                      <strong>Source: </strong>
                      {patient.source.details}
                      <br />
                    </MDTypography>
                  </MDBox>
                  <MDBox py={2} px={2} borderRadius={5} bgColor="light">
                    <MDTypography variant="h6">Contact Timeline</MDTypography>
                    {/* <MDBox p={2}>{timelineMap(patientArray)}</MDBox> */}
                    <MDBox p={2}>{timelineMap}</MDBox>
                  </MDBox>
                </MDBox>
              </Card>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Patient;
