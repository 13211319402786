import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDl7Ng9KTCV0HlA5pHfGfUJtZb4pTw2snc",
  authDomain: "horizonprescreeneranalytics.firebaseapp.com",
  projectId: "horizonprescreeneranalytics",
  storageBucket: "horizonprescreeneranalytics.appspot.com",
  messagingSenderId: "560522260594",
  appId: "1:560522260594:web:d0b99cd91b0a58c4ad12eb",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  window.localStorage.clear();
  signOut(auth);
};

export { auth, db, logInWithEmailAndPassword, logout };
