// Import useEffect
// import { useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ShoppingCard from "examples/Cards/BlogCards/ShoppingCard";

// Data import
// import { useMaterialUIController, setCart } from "context";
import storeData from "./data/storeData";

function warbler1Dash() {
  const newItems = storeData.map((a) => (
    <Grid item xs={12} md={6} lg={3} key={a.id}>
      <MDBox mb={1.5}>
        <ShoppingCard
          id={a.id}
          image={a.image}
          title={a.title}
          dimensions={a.dimensions}
          description={a.description}
          quantity={a.quantity}
          quantityOptions={a.quantityOptions}
          action={{
            type: a.action.type,
            route: a.action.route,
            color: a.action.color,
            label: a.action.label,
          }}
        />
      </MDBox>
    </Grid>
  ));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          {newItems}
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default warbler1Dash;
